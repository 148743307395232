import Head from 'next/head'
import { useRouter } from 'next/router'
import { publicConfig } from '~/config'

const Metadata: FC<Props> = (props) => {
	const router = useRouter()

	const siteTitle = props.pageTitle ? `${props.pageTitle} - ${props.siteTitle}` : props.siteTitle
	const siteUrl = new URL(publicConfig.vercel.url)
	siteUrl.pathname = router.pathname

	return (
		<Head>
			<title>
				{siteTitle}
			</title>
			<meta charSet="utf-8" />
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1, shrink-to-fit=no"
			/>
			{props.version || publicConfig.version ? (
				<meta
					name="commitSha"
					content={props.version || publicConfig.version}
				/>
			) : null}

			{/*Site Url*/}
			<meta
				name="og:url"
				property="og:url"
				content={siteUrl.href}
			/>

			{/*Site Title*/}
			<meta
				name="og:title"
				property="og:title"
				content={siteTitle}
			/>
			<meta
				name="og:site_name"
				property="og:site_name"
				content={siteTitle}
			/>
			<meta
				name="og:type"
				property="og:type"
				content={props.type || 'website'}
			/>
			{props.siteDescription ? (
				<>
					<meta
						name="description"
						content={props.siteDescription}
					/>
					<meta
						name="og:description"
						property="og:description"
						content={props.siteDescription}
					/>
				</>
			) : null}
			{props.siteKeywords && props.siteKeywords.length > 0 ? (
				<meta
					name="keywords"
					content={props.siteKeywords.join(',')}
				/>
			) : null}
			{props.image?.url ? (
				<>
					{props.image.url ? (
						<meta
							name="og:image"
							property="og:image"
							content={props.image.url}
						/>
					) : null}
					{props.image.width ? (
						<meta
							name="og:image:width"
							property="og:image:width"
							content={String(props.image.width)}
						/>
					) : null}
					{props.image.height ? (
						<meta
							name="og:image:height"
							property="og:image:height"
							content={String(props.image.height)}
						/>
					) : null}
					{props.image.alt || siteTitle ? (
						<meta
							name="og:image:alt"
							property="og:image:alt"
							content={props.image.alt || siteTitle}
						/>
					) : null}
				</>
			) : null}

			{/*Twitter*/}
			{props.type === 'video' ? (
				<meta
					name="twitter:card"
					property="twitter:card"
					content="player"
				/>
			) : (
				<meta
					name="twitter:card"
					property="twitter:card"
					content="summary_large_image"
				/>
			)}
			<meta
				name="twitter:title"
				property="twitter:title"
				content={siteTitle}
			/>
			<meta
				name="twitter:url"
				property="twitter:url"
				content={siteUrl.href}
			/>
			{props.siteDescription ? (
				<meta
					name="twitter:description"
					property="twitter:description"
					content={props.siteDescription}
				/>
			) : null}
			{props.image?.url ? (
				<>
					<meta
						name="twitter:image"
						property="twitter:image"
						content={props.image.url}
					/>
					<meta
						name="twitter:image"
						property="twitter:image:alt"
						content={props.image.alt || siteTitle}
					/>
				</>
			) : null}
			{props.type === 'video' && props.video?.url ? (
				<>
					{props.video.url ? (
						<>
							<meta
								name="twitter:player"
								property="twitter:player"
								content={props.video.url || siteUrl.href}
							/>
							<meta
								name="og:video:url"
								property="og:video:url"
								content={props.video.url || siteUrl.href}
							/>
							<meta
								name="og:video:secure_url"
								property="og:video:secure_url"
								content={props.video.url || siteUrl.href}
							/>
							<meta
								name="og:video:width"
								property="og:video:width"
								content={String(props.video.width || '1200')}
							/>
							<meta
								name="og:video:height"
								property="og:video:height"
								content={String(props.video.height || '720')}
							/>
							<meta
								name="twitter:player:width"
								property="twitter:player:width"
								content={String(props.video.width || '1200')}
							/>
							<meta
								name="twitter:player:height"
								property="twitter:player:height"
								content={String(props.video.height || '720')}
							/>
						</>
					) : null}
				</>
			) : null}
		</Head>
	)
}

type Props = {
  siteTitle: string
	type?: 'website' | 'video'
  pageTitle?: string
  version?: string
	siteDescription?: string
	siteKeywords?: string[]
	image?: {
		url: string
		aspectRatio: number
		width: string | number
		height: string | number
		alt: string
	}
	video?: {
		url: string
		width: string | number
		height: string | number
	}
}

export default Metadata
