import type { HTMLAttributes } from 'react'
import cx from 'classix'

const HeaderText: FCC<Props> = (props) => {
	const {
		type, className, children, ...headingProps
	} = props

	const classList = cx(
		type === 'hero' && 'text-5xl lg:text-7xl',
		type === 'hero-sub' && 'text-2xl lg:text-3xl',
		type === 'h1' && 'text-3xl lg:text-5xl',
		type === 'h2' && 'text-3xl lg:text-4xl',
		type === 'h3' && 'text-2xl lg:text-3xl',
		className,
	)

	if (type === 'hero') {
		return (
			<h1
				className={classList}
				{...headingProps}
			>
				{children}
			</h1>
		)
	} else if (type === 'h1') {
		return (
			<h1
				className={classList}
				{...headingProps}
			>
				{children}
			</h1>
		)
	} else if (type === 'h2' || type === 'hero-sub') {
		return (
			<h2
				className={classList}
				{...headingProps}
			>
				{children}
			</h2>
		)
	} else if (type === 'h3') {
		return (
			<h3
				className={classList}
				{...headingProps}
			>
				{children}
			</h3>
		)
	}

	throw new Error('Invalid heading type')
}

type Props = {
  type: 'hero' | 'hero-sub' | 'h1' | 'h2' | 'h3'
} & HTMLAttributes<HTMLHeadingElement>

export default HeaderText
