import Image from 'next/image'

const Footer: FC<Props> = (props) => {
	return (
		<footer className="pb-24 flex flex-col items-center space-y-6 lg:space-y-12">
			<div className="relative w-full h-8 lg:h-16">
				<Image
					fill
					className="object-contain object-center"
					src="/images/ecogreen_logo.png"
					alt="Ecogreen Logo"
					quality={100}
					sizes="50vw"
				/>
			</div>
			<div className="space-y-4 flex flex-col justify-center">
				{props.iconImageUrl ? (
					<div className="relative w-full h-16 lg:h-24">
						<Image
							fill
							className="object-contain object-center"
							src={props.iconImageUrl}
							alt={props.iconImageAlt || ''}
							sizes="50vw"
						/>
					</div>
				) : null}
				<div className="lg:text-xl font-light text-center space-y-4">
					{props.phoneNumber ? (
						<a
							className="block"
							href={`tel:+${props.phoneNumber.replace(/\D/g, '')}`}
						>
							{props.phoneNumber}
						</a>
					) : null}
					{props.email ? (
						<a
							className="block"
							href={`mailto:${props.email}`}
						>
							{props.email}
						</a>
					) : null}
				</div>
				<div className="text-sm lg:text-base font-light text-center">
					<p>
						&copy;
						{' '}
						{new Date().getFullYear()}
						{' '}
						Ecogreen, LLC
					</p>
				</div>
			</div>
		</footer>
	)
}

type Props = {
	phoneNumber?: string
	email?: string
	iconImageUrl?: string
	iconImageAlt?: string
}

export default Footer
