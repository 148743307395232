import Image from 'next/image'
import { Spin } from 'hamburger-react'
import { useCallback, useEffect } from 'react'
import { Router } from 'next/router'
import Link from 'next/link'
import { useMenu } from '~/client/contexts/MenuProvider'
import NavBar from '~/client/modules/Navigation/NavBar'

const Header: FC = () => {
	const {
		menuIsOpen,
		setMenuState,
		toggleMenuState,
	} = useMenu()

	const handleMenuClick = () => {
		toggleMenuState()
	}

	const handleRouteChange = useCallback(() => {
		if (menuIsOpen) {
			setMenuState(false)
		}
	}, [
		setMenuState,
		menuIsOpen,
	])

	useEffect(() => {
		// Top loading bar
		Router.events.on('routeChangeStart', handleRouteChange)

		return () => {
			Router.events.off('routeChangeStart', handleRouteChange)
		}
	}, [
		handleRouteChange,
	])

	return (
		<header className="flex justify-end items-center py-8 px-6 lg:px-20">
			<Link
				href="/"
				prefetch={false}
			>
				<div className="relative w-40 h-10 z-30">
					<Image
						fill
						className="object-contain"
						src="/images/ecogreen_logo.png"
						alt="Ecogreen Logo"
						quality={100}
						sizes="50vw"
					/>
				</div>
			</Link>
			<div className="flex justify-end flex-grow">
				<NavBar />
			</div>
			<button
				onClick={handleMenuClick}
				className="lg:hidden cursor-pointer z-10"
				onKeyDown={handleMenuClick}
			>
				<span className="sr-only">
					Menu Button
				</span>
				<Spin
					label="Menu Button"
					color={menuIsOpen ? '#15A34A' : '#fff'}
					toggled={menuIsOpen}
				/>
			</button>
		</header>
	)
}

export default Header
