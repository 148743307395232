import type { ComponentProps } from 'react'
import type Button from '~/client/components/Button'
import Image from 'next/image'
import Balancer from 'react-wrap-balancer'
import Script from 'next/script'
import Header from '~/client/modules/SiteGlobals/Header'
import Footer from '~/client/modules/SiteGlobals/Footer'
import MenuProvider from '~/client/contexts/MenuProvider'
import HeaderText from '~/client/components/HeaderText'
import LinkButton from '~/client/components/LinkButton'

const MainLayout: FCC<Props> = (props) => {
	const {
		heroImagePath = '/images/bg_waterfall.webp',
		heroImageBlurHash,
		headerText,
		subtitleText,
		buttons = [],
		gaId,
	} = props

	return (
		<div className="max-w-screen-2xl mx-auto relative h-full">
			{gaId ? (
				<>
					<Script
						async
						src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
					/>
					<Script
						id="google-analytics"
						dangerouslySetInnerHTML={{
							__html: `
								window.dataLayer = window.dataLayer || [];
								function gtag(){dataLayer.push(arguments);}
								gtag('js', new Date());
								gtag('config', '${gaId}');
							`,
						}}
					/>
				</>
			) : null}
			<div className="relative flex flex-col pb-12">
				<div className="absolute top-0 left-0 h-full w-full bg-black -z-10 min-h-[24rem]">
					<Image
						fill
						loading="eager"
						className="object-cover object-top opacity-80"
						src={heroImagePath || '/images/bg_waterfall.webp'}
						alt="Hero background banner"
						priority
						placeholder="blur"
						blurDataURL={heroImageBlurHash || undefined}
						sizes="100vw"
					/>
				</div>
				<div className="w-full z-10">
					<MenuProvider>
						<Header />
					</MenuProvider>
				</div>
				{headerText || subtitleText || buttons.length > 0 ? (
					<div className="h-full flex items-center grow pb-6 pt-12">
						<div className="px-6 lg:px-20 space-y-6">
							{headerText || subtitleText ? (
								<div className="space-y-6">
									<Balancer>
										{headerText ? (
											<HeaderText
												type="hero"
												className="text-white font-black"
											>
												{headerText}
											</HeaderText>
										) : null}
										{subtitleText ? (
											<HeaderText
												type="hero-sub"
												className="text-white font-light"
											>
												{subtitleText}
											</HeaderText>
										) : null }
									</Balancer>
								</div>
							) : null}
							{buttons ? (
								<div className="lg:flex lg:gap-6 space-y-5 lg:space-y-0">
									{buttons.map((button) => {
										if (button.label && button.style && button.href) {
											return (
												<LinkButton
													key={button.label}
													linkStyle={button.style}
													href={button.href}
												>
													{button.label}
												</LinkButton>
											)
										}

										return null
									})}
								</div>
							) : null}
						</div>
					</div>
				) : null}
			</div>
			<div className="relative mb-16">
				{props.children}
			</div>
			<Footer {...props.footer} />
		</div>
	)
}

type Props = {
	gaId?: string | null
	heroImagePath?: string | null
	heroImageBlurHash?: string | null
	headerText?: string | JSX.Element | null
	subtitleText?: string | JSX.Element | null
	buttons?: {
		label: string
		href: string
		style?: ComponentProps<typeof Button>['buttonStyle']
	}[]
	footer: ComponentProps<typeof Footer>
}

export default MainLayout
