import Link from 'next/link'
import { useRouter } from 'next/router'
import cx from 'classix'
import { useMenu } from '~/client/contexts/MenuProvider'

type NavigationLink = {
	label: string
	href: string
	external: boolean
}

const navigationLinks: NavigationLink[] = [
	{
		label: 'Home',
		href: '/',
		external: false,
	},
	{
		label: 'About',
		href: '/about',
		external: false,
	},
	{
		label: 'Products',
		href: '/products',
		external: false,
	},
	{
		label: 'Find a Rep',
		href: '/reps',
		external: false,
	},
	{
		label: 'Resources',
		href: '/resources',
		external: false,
	},
	{
		label: 'Contact',
		href: '/contact',
		external: false,
	},
]

const NavBar: FC = () => {
	const {
		menuIsOpen,
	} = useMenu()
	const {
		asPath,
	} = useRouter()

	const navClassList = cx(
		'z-10 fixed top-0 left-0 bottom-0 lg:static',
		'h-full lg:h-auto w-full lg:w-auto select-none lg:select-auto',
		menuIsOpen && 'bg-zinc-900/80 lg:bg-transparent',
		'transition-[backdrop-filter,opacity] duration-300 lg:transition-none',
		'pt-24 lg:pt-0 py-9 lg:py-0 space-y-8 lg:space-y-0',
		'overflow-hidden lg:overflow-visible',
		'lg:flex lg:items-center',
		menuIsOpen ? 'backdrop-blur-sm lg:backdrop-blur-none' : 'backdrop-blur-none',
		menuIsOpen ? 'opacity-100' : 'opacity-0 lg:opacity-100',
		!menuIsOpen && 'pointer-events-none lg:pointer-events-auto',
	)

	const ulClassList = cx(
		'lg:flex lg:space-x-6 lg:items-center',
	)

	return (
		<nav className={navClassList}>
			<ul className={ulClassList}>
				{navigationLinks.map((link) => {
					const isActive = !link.external && asPath === link.href

					const classList = cx(
						'font-semibold lg:font-normal',
						'text-green-600 text-2xl lg:text-white',
						'group-active:border-b-4 group-active:border-green-600',
						!isActive && 'group-hover:border-b-4 group-hover:border-green-600',
						!isActive && 'lg:group-hover:border-b-2 lg:group-hover:border-white',
						isActive && 'border-green-600 lg:border-white border-b-4 lg:border-b-2',
						'ml-6 lg:ml-0 lg:px-1 lg:py-2',
					)

					return (
						<li
							key={link.label}
							className="group cursor-pointer py-3 lg:py-0"
						>
							{link.external ? (
								<a
									className={classList}
									href={link.href}
									target="_blank"
									rel="noreferrer"
								>
									{link.label}
								</a>
							) : (
								<Link
									className={classList}
									href={link.href}
									prefetch={false}
								>
									{link.label}
								</Link>
							)}
						</li>
					)
				})}
			</ul>

		</nav>
	)
}

export default NavBar
