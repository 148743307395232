import type { Dispatch, SetStateAction } from 'react'
import {
	createContext, useContext, useEffect, useState,
} from 'react'
import { useLockedBody, useMediaQuery } from 'usehooks-ts'

interface Context {
  menuIsOpen: boolean
	setMenuState: Dispatch<SetStateAction<boolean>>
	toggleMenuState: () => void
}

const MenuContext = createContext<Context>({
	menuIsOpen: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setMenuState: () => {
		return null
	},
	toggleMenuState: () => {
		return null
	},
})

const MenuProvider: FCC = (props) => {
	const matches = useMediaQuery('(min-width: 1024px)')

	const [
		locked,
		setLocked,
	] = useLockedBody(false, '__next')

	const [
		menuIsOpen,
		setMenuIsOpen,
	] = useState(false)

	useEffect(() => {
		if (!menuIsOpen && locked) {
			setLocked(false)
		} else if (menuIsOpen && !locked) {
			setLocked(true)
		}
	}, [
		menuIsOpen,
		locked,
		setLocked,
	])

	useEffect(() => {
		if (menuIsOpen && matches) {
			setMenuIsOpen(false)
		}
	}, [
		matches,
		menuIsOpen,
	])

	const toggleMenuState = () => {
		setMenuIsOpen((state) => {
			return !state
		})
	}

	return (
		<MenuContext.Provider
			value={{
				menuIsOpen,
				setMenuState: setMenuIsOpen,
				toggleMenuState,
			}}
		>
			{props.children}
		</MenuContext.Provider>
	)
}

export const useMenu = () => {
	return useContext(MenuContext)
}

export default MenuProvider
