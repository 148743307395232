import {
	type ComponentProps,
	type ElementType,
} from 'react'
import cx from 'classix'
import Link from 'next/link'

const LinkButton: FCC<Props> = (props) => {
	const {
		linkStyle = 'primary',
		IconLeft,
		IconRight,
		className,
		...restProps
	} = props

	const linkClassList = cx(
		'inline-block',
		'leading-none font-light',
		'rounded-tl-full rounded-br-full',
		'text-white text-xl ',
		linkStyle === 'primary' && 'bg-green-700 hover:bg-green-800',
		linkStyle === 'secondary' && 'backdrop-blur bg-green-200/20 hover:bg-green-800/50',
		'py-5 px-8',
		className,
	)

	const opensNewTab = String(restProps.href).startsWith('http')

	if (opensNewTab) {
		restProps.target = '_blank'
	}

	return (
		<Link
			className={linkClassList}
			{...restProps}
			prefetch={false}
		>
			{opensNewTab ? (
				<span className="sr-only">
					This link opens a new tab.
				</span>
			) : null}
			{IconLeft ? (
				<IconLeft className="h-4 w-4 inline-block" />
			) : null}
			<div className="px-3 inline-block">
				{props.children}
			</div>
			{IconRight ? (
				<IconRight className="h-4 w-4 inline-block" />
			) : null}
		</Link>
	)
}

type Props = {
  linkStyle?: 'primary' | 'secondary'
  IconRight?: ElementType
  IconLeft?: ElementType
} & ComponentProps<typeof Link>

export default LinkButton
